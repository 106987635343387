import { PersonaType } from '~/tenants/common/TenantPersona';

export enum OrderState {
  CANCELLED = 'CANCELLED',
  DRAFT = 'DRAFT',
  HOLD = 'HOLD',
  QUOTE = 'QUOTE',
  SUBMITTED = 'SUBMITTED',
  CONFIRMED = 'CONFIRMED',
  SCHEDULED = 'SCHEDULED',
  STARTED = 'STARTED',
  REVIEW = 'REVIEW',
  UNBUNDLED = 'UNBUNDLED',
  BUNDLED = 'BUNDLED',
  UNPAID = 'UNPAID',
  REFUND = 'REFUND',
  COMPLETED = 'COMPLETED',
}

const ORDER_STATE_ORDER = Object.values(OrderState);

export function order_state_compare(a: OrderState, b: OrderState): number {
  return ORDER_STATE_ORDER.indexOf(b) - ORDER_STATE_ORDER.indexOf(a);
}

export enum OrderStatus {
  WIZARD = 'WIZARD',
  DRAFT = 'DRAFT',
  QUOTE = 'QUOTE',
  SUBMITTED = 'SUBMITTED',
  CONFIRMED = 'CONFIRMED',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
  PAID = 'PAID',
  CLOSED = 'CLOSED',
  CANCELLED = 'CANCELLED',
}

export enum JobEditorStatus {
  UNSUBMITTED = 'UNSUBMITTED',
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED',
}

const orderStatusOrder = [
  OrderStatus.WIZARD,
  OrderStatus.DRAFT,
  OrderStatus.QUOTE,
  OrderStatus.SUBMITTED,
  OrderStatus.CONFIRMED,
  OrderStatus.STARTED,
  OrderStatus.COMPLETED,
  OrderStatus.PAID,
  OrderStatus.CLOSED,
  OrderStatus.CANCELLED,
];

export function order_status_same_or_after(status: OrderStatus, isSameOrAfter: OrderStatus): boolean {
  const statusIndex = orderStatusOrder.indexOf(status);
  const targetIndex = orderStatusOrder.indexOf(isSameOrAfter);

  return statusIndex >= targetIndex;
}

export function order_status_before(status: OrderStatus, isBefore: OrderStatus): boolean {
  const statusIndex = orderStatusOrder.indexOf(status);
  const targetIndex = orderStatusOrder.indexOf(isBefore);

  return statusIndex < targetIndex;
}

export enum JobStatus {
  DRAFT = 'DRAFT',
  CREATED = 'CREATED',
  CONFIRMED = 'CONFIRMED',
  WAITING = 'WAITING',
  INPUTTING = 'INPUTTING',
  INPUTTED = 'INPUTTED',
  ASSIGNED = 'ASSIGNED',
  SCHEDULED = 'SCHEDULED',
  STARTED = 'STARTED',
  PROGRESS = 'PROGRESS',
  FINISHED = 'FINISHED',
  UPLOADED = 'UPLOADED',
  SUBMITTED = 'SUBMITTED',
  EDITED = 'EDITED',
  REVIEW = 'REVIEW',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export enum JobStep {
  CONFIGURE = 'CONFIGURE',
  INPUT = 'INPUT',
  CONFIRM = 'CONFIRM',
  ASSIGN = 'ASSIGN',
  PROOF = 'PROOF',
  SCHEDULE = 'SCHEDULE',
  ONSITE = 'ONSITE',
  OFFSITE = 'OFFSITE',
  UPLOAD = 'UPLOAD',
  SUBMIT = 'SUBMIT',
  EDIT = 'EDIT',
  REVIEW = 'REVIEW',
  GENERATE = 'GENERATE',
  DELIVER = 'DELIVER',
}

export type JobStepStateValue =
  | {
      user_id: string;
    }
  | {
      name: string;
    };

export interface JobStepState {
  step: JobStep;
  completed: boolean;
  eligible: boolean;
  started: boolean;
  warning: boolean;
  overdue: boolean;
  skipped: boolean;
  removable: boolean;
  persona: PersonaType;
  value?: JobStepStateValue;
}

export function job_step_includes(state: JobStepState[], step: JobStep) {
  return state.some((s) => s.step === step);
}

export function job_step_eligible(state: JobStepState[], step: JobStep) {
  return state.some((s) => s.step === step && s.eligible);
}

export function job_step_incomplete(state: JobStepState[], step: JobStep) {
  return state.some((s) => s.step === step && !s.completed && s.eligible);
}

export function job_step_started(state: JobStepState[], step: JobStep) {
  return state.some((s) => s.step === step && s.started);
}

export function job_step_completed(state: JobStepState[], step: JobStep) {
  return state.some((s) => s.step === step && s.completed);
}

const jobStatusOrder = [
  JobStatus.DRAFT,
  JobStatus.CREATED,
  JobStatus.CONFIRMED,
  JobStatus.WAITING,
  JobStatus.INPUTTING,
  JobStatus.INPUTTED,
  JobStatus.ASSIGNED,
  JobStatus.SCHEDULED,
  JobStatus.STARTED,
  JobStatus.PROGRESS,
  JobStatus.FINISHED,
  JobStatus.UPLOADED,
  JobStatus.SUBMITTED,
  JobStatus.EDITED,
  JobStatus.REVIEW,
  JobStatus.COMPLETED,
  JobStatus.CANCELLED,
];

export function job_status_same_or_after(status: JobStatus, isSameOrAfter: JobStatus): boolean {
  const statusIndex = jobStatusOrder.indexOf(status);
  const targetIndex = jobStatusOrder.indexOf(isSameOrAfter);

  return statusIndex >= targetIndex;
}

export function job_status_before(status: JobStatus, isBefore: JobStatus): boolean {
  const statusIndex = jobStatusOrder.indexOf(status);
  const targetIndex = jobStatusOrder.indexOf(isBefore);

  return statusIndex < targetIndex;
}

export enum AppointmentLock {
  DAY = 'DAY',
  TIME = 'TIME',
}

export enum AppointmentStatus {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  ENROUTE = 'ENROUTE',
  ONSITE = 'ONSITE',
  OFFSITE = 'OFFSITE',
  COMPLETE = 'COMPLETE',
}

export enum AppointmentCancel {
  SAME_DAY = 'SAME_DAY',
  ONSITE = 'ONSITE',
  ENROUTE = 'ENROUTE',
}

export enum TimeZone {
  UTC = 'UTC',
  US_ALASKA = 'US/Alaska',
  US_EASTERN = 'US/Eastern',
  US_CENTRAL = 'US/Central',
  US_MOUNTAIN = 'US/Mountain',
  US_PACIFIC = 'US/Pacific',
  US_HAWAII = 'US/Hawaii',
  EU_AMSTERDAM = 'Europe/Amsterdam',
  EU_LONDON = 'Europe/London',
  EU_STOCKHOLM = 'Europe/Stockholm',
  AF_CASABLANCA = 'Africa/Casablanca',
  AP_SINGAPORE = 'Asia/Singapore',
  AUSTRAILIA = 'America/Atikokan',
}

export enum ChannelType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  CALL = 'CALL',
  SLACK = 'SLACK',
}

export enum OrderInvoiceStatus {
  DRAFT = 'DRAFT',
  UNPAID = 'UNPAID',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  VOID = 'VOID',
}
